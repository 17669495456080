import { combineReducers } from '@reduxjs/toolkit';

import {
  memberSlice,
  badgesSlice,
  siteSlice,
  blogPostsSlice,
  appSettingsSlice,
  uiSlice,
} from './slices';

export const rootReducer = combineReducers({
  member: memberSlice.reducer,
  badges: badgesSlice.reducer,
  blogPosts: blogPostsSlice.reducer,
  site: siteSlice.reducer,
  ui: uiSlice.reducer,
  appSettings: appSettingsSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
