import { createAsyncThunk } from '@reduxjs/toolkit';

import { ThunkApiConfig } from '../../../types';

export const navigateToPath = createAsyncThunk<void, string, ThunkApiConfig>(
  'site/navigateToPath',
  async (path, { extra: { wixCodeApi } }) => {
    const { location } = wixCodeApi;
    location.to?.(`${path}`);
  },
);
