import { createSlice } from '@reduxjs/toolkit';

import { BlogPosts } from '../../../types';
import { fetchPosts } from './thunk';

export type State = BlogPosts;

const initialState: State = {
  paging: { count: 0, total: 0, offset: 0 },
  createBlogPostUrl: { url: '', relativeUrl: '' },
  arePostsLoading: false,
};

export const blogPostsSlice = createSlice({
  name: 'blogPosts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPosts.pending, (state) => {
      state.arePostsLoading = true;
    });
    builder.addCase(fetchPosts.rejected, (state) => {
      state.arePostsLoading = false;
    });
    builder.addCase(fetchPosts.fulfilled, (state, { payload }) => {
      state.paging.count = state.paging.count + payload.fetchedPostsCount;
      state.paging.offset = payload.pagingOffset;
      state.arePostsLoading = false;
    });
  },
});
