import { MEMBERS_AREA, WIX_BLOG } from '@wix/app-definition-ids';
import { ITEM_TYPES } from '@wix/advanced-seo-utils';
import {
  createMembersAreaWidgetPluginService,
  IMembersAreaWidgetPluginService,
  routerDataServiceFactory,
} from '@wix/members-area-widget-plugin-lib/viewer';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { Set } from '@wix/ambassador-members-v1-member/types';

import {
  ControllerContext,
  ControllerProps,
  Experiment,
  FlowApi,
  MembersAreaApi,
  MemberSEOData,
  Toast,
  WarmupDataKey,
  WarmupDataService,
  WixCodeApi,
} from '../../types';
import { RootState } from '../../store/root-reducer';
import { createStore, getActionHandlers, Store } from '../../store';
import * as profileApi from '../../server/profile.api';
import settingsParams, { defaultPostListPageSize } from './settingsParams';
import { toNumberOrDefault } from '../../services/number-converter';
import { initWarmupDataService } from '../../services/warmup-data';
import {
  createSettingsListener,
  registerControllerListeners,
  registerUserListeners,
  registerWidgetPluginHostListeners,
} from './controller-listeners';

const withLocaleHeadersFactory = (flowAPI: FlowApi) => {
  return <T>(requestOptions: T) => {
    const { multilingual } = flowAPI.environment;
    if (multilingual?.isEnabled) {
      const currentLanguage = multilingual.siteLanguages.find(
        (lang) => lang.languageCode === multilingual.currentLanguage,
      );
      if (currentLanguage) {
        const wixLinguistHeader = {
          'x-wix-linguist': `${currentLanguage.languageCode}|${currentLanguage.locale}|${currentLanguage.isPrimaryLanguage}|${flowAPI.environment.appDefinitionId}`,
        };

        return {
          ...requestOptions,
          headers: { ...requestOptions, ...wixLinguistHeader },
        };
      }
    }

    return requestOptions;
  };
};

const getMemberIds = async (
  flowApi: FlowApi,
  widgetPluginService: IMembersAreaWidgetPluginService,
) => {
  const { window, user } = flowApi.controllerConfig.wixCodeApi;
  const routerDataService = routerDataServiceFactory(
    widgetPluginService,
    user,
    window,
  );

  const routeData = await routerDataService.getRouteData();

  const currentMemberId = routeData?.currentMemberIdentifier?.id ?? null;
  const viewedMemberId = routeData?.viewedMemberIdentifier?.id ?? null;

  return { currentMemberId, viewedMemberId };
};

const getCreateBlogPostUrl = async (flowAPI: FlowApi) => {
  const { wixCodeApi } = flowAPI.controllerConfig;
  const { url, relativeUrl } = await wixCodeApi.site.getSectionUrl({
    appDefinitionId: WIX_BLOG,
    sectionId: 'post',
  });

  return {
    url: `${url}/create-post`,
    relativeUrl: `${relativeUrl}/create-post`,
  };
};

const getSectionVisibilityProps = ({ settings }: FlowApi) => ({
  showAboutSection: settings.get(settingsParams.showAboutSection),
  showJoinDate: settings.get(settingsParams.showJoinDate),
  showBadgesSection: settings.get(settingsParams.showBadgesSection),
  showOverviewSection: settings.get(settingsParams.showOverviewSection),
  showBlogPostsSection: settings.get(settingsParams.showBlogPostsSection),
  showCommentsStats: settings.get(settingsParams.showCommentsStats),
  showLikesReceivedStats: settings.get(settingsParams.showLikesReceivedStats),
  showTopCommentsStats: settings.get(settingsParams.showTopCommentsStats),
});

const getShouldFetchFullMemberInfo = (
  flowAPI: FlowApi,
  widgetPluginService: IMembersAreaWidgetPluginService,
  showOverviewSection: boolean,
) => {
  const { controllerConfig } = flowAPI;
  const isWidgetPlugin = widgetPluginService.getIsWidgetPlugin();

  return (
    showOverviewSection ||
    isWidgetPlugin ||
    getIsMembersAreaSeoEnabled(flowAPI, controllerConfig.wixCodeApi)
  );
};

const fetchInitialState = async (
  flowAPI: FlowApi,
  widgetPluginService: IMembersAreaWidgetPluginService,
) => {
  const withLocaleHeaders = withLocaleHeadersFactory(flowAPI);
  const { settings, experiments } = flowAPI;
  const { language, multilingual, isRTL, isViewer } = flowAPI.environment;
  const { currentMemberId, viewedMemberId } = await getMemberIds(
    flowAPI,
    widgetPluginService,
  );
  const sectionVisibilityProps = isViewer
    ? getSectionVisibilityProps(flowAPI)
    : ({} as ReturnType<typeof getSectionVisibilityProps>);

  const shouldFetchFullMemberInfo = await getShouldFetchFullMemberInfo(
    flowAPI,
    widgetPluginService,
    sectionVisibilityProps.showOverviewSection,
  );

  const fetchMemberFieldSet = shouldFetchFullMemberInfo ? Set.FULL : Set.PUBLIC;

  if (viewedMemberId) {
    const { data } = await flowAPI.httpClient.request(
      withLocaleHeaders(
        profileApi.getInitialData({
          ...sectionVisibilityProps,
          currentMemberId,
          viewedMemberId,
          postsPerPage: toNumberOrDefault(
            settings.get(settingsParams.postListPageSize),
            defaultPostListPageSize,
          ),
          language,
          isRTL,
          isMultilingualEnabled: multilingual?.isEnabled ?? false,
          isMembersAboutV2Enabled: experiments.enabled(
            Experiment.UseMembersAboutV2,
          ),
          shouldAddPostListToSeoData: experiments.enabled(
            Experiment.EnablePostListInSEO,
          ),
          shouldAddRicosContentToSeoData: experiments.enabled(
            Experiment.EnableRicosContentInSEO,
          ),
          fetchMemberFieldSet,
        }),
      ),
    );

    return data;
  }

  return {
    member: {} as any,
    badges: [],
    site: {
      sameSessionMember: false,
      isForumInstalled: false,
      isBlogInstalled: false,
    },
    blogPosts: { paging: { count: 0, offset: 0, total: 0 } },
    seoData: null,
  };
};

type FetchInitialStateResponse = Awaited<ReturnType<typeof fetchInitialState>>;

export const getInitialData = async (
  flowAPI: FlowApi,
  widgetPluginService: IMembersAreaWidgetPluginService,
  warmupDataService: WarmupDataService,
): Promise<{
  initialState: RootState;
  seoData: MemberSEOData | null;
}> => {
  let data: FetchInitialStateResponse;

  if (flowAPI.environment.isSSR) {
    data = await fetchInitialState(flowAPI, widgetPluginService);
    warmupDataService.set(WarmupDataKey.InitialData, data);
  } else {
    const warmupData = warmupDataService.get<FetchInitialStateResponse>(
      WarmupDataKey.InitialData,
    );

    data =
      warmupData ?? (await fetchInitialState(flowAPI, widgetPluginService));
  }

  const createBlogPostUrl = data.site.isBlogInstalled
    ? await getCreateBlogPostUrl(flowAPI)
    : { url: '', relativeUrl: '' };

  return {
    initialState: {
      member: data.member,
      badges: data.badges,
      site: data.site,
      blogPosts: {
        ...data.blogPosts,
        createBlogPostUrl,
        arePostsLoading: false,
      },
      ui: {
        isPublicProfilePreview: false,
        toast: {
          type: Toast.None,
          isVisible: false,
        },
        publishButton: {
          isDisabled: false,
        },
      },
      appSettings: {
        tabOpened: null,
        sectionState: null,
        sectionOpened: null,
      },
    },
    seoData: data.seoData,
  };
};

/**
 * Copy-pasted code from the @wix/ricos-common
 * Not used package by itself because it adds 30 kb to the bundle
 * https://github.com/wix-private/wix-ricos/blob/master/wix-ricos-common/src/utils/biDefaultParams.ts#L89
 */
const extractUoUBIParams = ({
  controllerConfig: { appParams, platformAPIs },
}: FlowApi) => {
  const loggerFactory = platformAPIs.biLoggerFactory?.() as any;
  const defaults = loggerFactory?.loggerClientFactory?._defaults;

  return {
    appId: appParams.appDefinitionId,
    bsi: defaults?.bsi?.(),
    instanceId: appParams.instanceId,
    metaSiteId: platformAPIs.bi?.metaSiteId,
    siteOwnerId: platformAPIs.bi?.ownerId,
    visitorId: platformAPIs.bi?.visitorId,
  };
};

export const getControllerProps = (
  flowAPI: FlowApi,
  store: Store,
): ControllerProps => ({
  ...store.getState(),
  ...getActionHandlers(store),
  ...(flowAPI.controllerConfig.platformAPIs.bi?.viewerName ===
    'thunderbolt' && { fitToContentHeight: true }),
  instance: flowAPI.controllerConfig.appParams.instance,
  defaultBIParams: extractUoUBIParams(flowAPI),
  isRendered: true,
});

export const getSEODataWithProfileURL = async (
  wixCodeApi: WixCodeApi,
  seoData: MemberSEOData,
) => {
  const { location } = wixCodeApi;

  return {
    ...seoData,
    member: {
      ...seoData.member,
      profile: {
        ...seoData.member.profile,
        profilePageUrl: location.url,
      },
    },
  };
};

export const getControllerContext = ({
  flowAPI,
  controllerConfig: { wixCodeApi, setProps },
}: ControllerParams): ControllerContext => {
  const settingsListener = createSettingsListener();
  const widgetPluginService = createMembersAreaWidgetPluginService();
  const warmupDataService = initWarmupDataService(wixCodeApi.window.warmupData);

  return {
    flowAPI,
    wixCodeApi,
    setProps,
    widgetPluginService,
    warmupDataService,
    settingsListener,
  };
};

const getMembersAreaPublicAPI = (
  flowAPI: FlowApi,
  wixCodeApi: WixCodeApi,
): Promise<MembersAreaApi> | undefined => {
  return wixCodeApi.site.getPublicAPI(MEMBERS_AREA).catch((error) => {
    flowAPI.reportError(error);
  });
};

const getIsMembersAreaSeoEnabled = async (
  flowAPI: FlowApi,
  wixCodeApi: WixCodeApi,
): Promise<boolean> => {
  const membersAreaPublicAPI = await getMembersAreaPublicAPI(
    flowAPI,
    wixCodeApi,
  );

  return membersAreaPublicAPI?.getIsMembersAreaSeoEnabled() ?? false;
};

const maybeCallRenderSeoTags = async ({
  flowAPI,
  wixCodeApi,
  seoData,
  isViewer,
  isWidgetPlugin,
}: {
  flowAPI: FlowApi;
  wixCodeApi: WixCodeApi;
  seoData: MemberSEOData | null;
  isViewer: boolean;
  isWidgetPlugin: boolean;
}) => {
  if (!isViewer || !seoData || isWidgetPlugin) {
    return;
  }

  const isRenderSEOTagsEnabled = await getIsMembersAreaSeoEnabled(
    flowAPI,
    wixCodeApi,
  );

  if (!isRenderSEOTagsEnabled) {
    return;
  }

  return renderSEOTags(wixCodeApi, seoData);
};

export const initialiseAboutWidget = async ({
  flowAPI,
  wixCodeApi,
  widgetPluginService,
  warmupDataService,
  settingsListener,
  setProps,
}: ControllerContext) => {
  const { initialState, seoData } = await getInitialData(
    flowAPI,
    widgetPluginService,
    warmupDataService,
  );
  const store = createStore(
    { flowAPI, wixCodeApi, widgetPluginService, warmupDataService },
    initialState,
  );
  const isWidgetPlugin = widgetPluginService.getIsWidgetPlugin();
  const { isViewer } = flowAPI.environment;

  store.subscribe(() => setProps(store.getState()));
  setProps(getControllerProps(flowAPI, store));
  registerUserListeners(flowAPI, store, isWidgetPlugin);
  registerControllerListeners(flowAPI, settingsListener, store);
  registerWidgetPluginHostListeners({
    wixCodeApi,
    widgetPluginService,
    isViewer,
    store,
    seoData,
  });

  await maybeCallRenderSeoTags({
    flowAPI,
    wixCodeApi,
    seoData,
    isViewer,
    isWidgetPlugin,
  });
};

export const renderSEOTags = async (
  wixCodeApi: WixCodeApi,
  seoData: MemberSEOData,
) => {
  // TODO: Take MEMBERS_AREA_AUTHOR_PROFILE from @wix/advanced-seo-utils when available
  const itemType = seoData.memberPosts?.length
    ? 'MEMBERS_AREA_AUTHOR_PROFILE'
    : ITEM_TYPES.MEMBERS_AREA_PROFILE;
  const itemData = await getSEODataWithProfileURL(wixCodeApi, seoData);

  wixCodeApi.seo.renderSEOTags({
    itemType,
    itemData,
  });
};
