import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  SettingsTab,
  SettingsSection,
  SettingsSectionState,
} from '../../../types';

export type State = {
  tabOpened: SettingsTab | null;
  sectionOpened: SettingsSection | null;
  sectionState: SettingsSectionState | null;
};

const initialState: State = {
  tabOpened: null,
  sectionOpened: null,
  sectionState: null,
};

export const appSettingsSlice = createSlice({
  name: 'appSettings',
  initialState,
  reducers: {
    openTab: (state, action: PayloadAction<SettingsTab | null>) => {
      state.tabOpened = action.payload;
      state.sectionOpened = null;
      state.sectionState = null;
    },
    openSection: (state, action: PayloadAction<SettingsSection | null>) => {
      state.sectionOpened = action.payload;
      state.sectionState = null;
    },
    changeSectionState: (
      state,
      action: PayloadAction<SettingsSectionState | null>,
    ) => {
      state.sectionState = action.payload;
    },
  },
});

const { actions } = appSettingsSlice;

export const { openTab, openSection, changeSectionState } = actions;
